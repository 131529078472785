.faq{
    background-color: var(--faqBackColor);
    padding: 74px 0;

    &-left{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 500px;
        img{
            height: 100%;
            width: 380px;
            object-fit: cover;
        }

        .play{
            position: absolute;
            z-index: 1;
            width: 84px;
            height: 84px;
            background-color: #FFFFFF;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover{
                i{
                    transform: scale(1.1);
                }
            }

            i{
                font-size: 23px;
                color: var(--accentColor);
                transition: .2s;
                position: relative;
                right: -2px;
            }
        }
    }

    .nav{
        .nav-link{
            background-color: var(--faqItemColor);
            padding: 23px 40px;
            font-weight: 700;
            font-size: 17px;
            color: var(--faqTitleColor);
            transition: .2s;

            &:hover{
                background-color: var(--faqColor);
            }

            &.active{
                background-color: var(--faqColor);
                color: var(--faqTitleActive)
            }
        }
        .nav-item{
            &:not(:last-child){
                margin-right: 4px;
            }
        }
    }

    .faq-content{
        height: 500px;
        overflow: hidden;

        .pane-img{
            position: absolute;
            left: 0;
            top: 0;
            width: 380px;
            height: 100%;
            object-fit: cover;
        }
    }
    .tab-content{
        padding: 32px 40px 32px;
        background-color: var(--faqColor);
        width: 100%;
        height: 100%;

        p{
            color: var(--faqFontColor);
        }
    }
    .tab-pane{
        height: 100%;
        overflow: auto;
        div{
            margin-bottom: 50px;
            margin-right: 20px;
        }
    }
    .faq-wrapper{
        display: grid;
        grid-template-columns: 380px 1fr;
        position: relative;
    }

}